import React, { useRef, useState } from 'react'
import './Dash.css'
import NavBar from '../Components/NavBar'
import LeftBar from '../Components/LeftBar'

import ExpBookings from './ExpBookings'
import FlightBookings from './FlightBookings'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch} from '@fortawesome/free-solid-svg-icons'
import { faAdd} from '@fortawesome/free-solid-svg-icons'


function Bookings() {
    const [showProgress, setShowProgress] = useState(true);
    const [showSignInbtn, setShowSignInbtn] = useState(false);
  
    const [showWineBtn, setshowWineBtn] = useState(true);
    const [showFoodBtn, setshowFoodBtn] = useState(true);
  
    const [showWineBtn2, setshowWineBtn2] = useState(false);
    const [showFoodBtn2, setshowFoodBtn2] = useState(false);
  
  
    function showWine(){
  
      setShowProgress(true);
      setShowSignInbtn(false)
  
      setshowFoodBtn2(false)
      setshowFoodBtn(true)
      setshowWineBtn(true)
      setshowWineBtn2(false)
  
  
    }
  
    function showFood(){
      setShowProgress(false);
      setShowSignInbtn(true)
      
      setshowFoodBtn2(true)
      setshowFoodBtn(false)
      setshowWineBtn(false)
      setshowWineBtn2(true)
  
    }
  
  
    return (
  <div className='contentMain'> 
          <LeftBar/>
          <div className='theRightOne'> 
            <div className='DashTop'>
              <h1>Bookings</h1>
              <NavBar/>
            </div>
  
            <div className='dataCompTop'>
  
  <div className='mymenu'>
      <h5>All Bookings</h5>
  
     {showWineBtn &&  <button className='wineBtn' onClick={showWine}>Experiences Bookings</button>}
     {showFoodBtn && <button className='foodBtn' onClick={showFood}>Flight Bookings</button>}
  
     {showWineBtn2 &&  <button className='wineBtn2' onClick={showWine}>Experiences Bookings</button>}
     {showFoodBtn2 && <button className='foodBtn2' onClick={showFood}>Flight Bookings</button>}
  </div>
  
  
  <div className='searchContainer'>
      <FontAwesomeIcon icon={faSearch} className="searchIcon"/>
      <input type="text" placeholder='Type to search' className='searchAdmin'/>
      <button>Go</button>
  </div>
  </div>

  
          {showSignInbtn && 
           <FlightBookings/>
          
          }   
           {showProgress && 
           <ExpBookings/>
           }
          
           
  
           
            
          </div>    
      </div>
    )
  
}

export default Bookings