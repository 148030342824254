import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, doc, orderBy, setDoc,query, getDocs, deleteDoc, where, updateDoc} from "@firebase/firestore"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';

import { getAuth, onAuthStateChanged } from "firebase/auth";


function ExpensesComp() {


    const [modalShowUp, setModalShowUp] = React.useState(false);
    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app);
    const auth = getAuth();
  
  
    //fetch all menu items
    useEffect(() => {     
              const fetchData = async () => {  
                const q = query(collection(db, 'expenses'), orderBy('myTimestamp', 'desc'));
              let studentsItem = [];
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                  studentsItem.push({ id: doc.id, ...doc.data() });
                  setStudentList([...studentsItem]);   
              });
      
          
          };
      fetchData();
      //end magic
  
      }, []);
      //end fetching menu items
  
  
    //delete buyer
    function deleteThisItem(docid){

        const q = query(collection(db, "expenses"), where("docid", "==", docid));
        getDocs(q).then((QuerySnapshot)=>{
            QuerySnapshot.forEach((thdoc)=>{
                let itemname = thdoc.data().firstName;
                console.log(itemname)

                swal({
                  title: `Delete an Expense`,
                  text: `Are you sure you want to delete this expense ?`,
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    deleteDoc(doc(db, "expenses", docid))
                    .then(()=>{
                      swal("Deleted", "" , "success");
                      swal(`Expense has been deleted!`, {
                        icon: "success",
                      }).then(()=>{
                        window.location.reload();
                      })
                      
                    })

                  } else {
                    swal("Cancelled");
                  }
                });                    
            })
        })

    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
      
        return formattedDate;
      }

      function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true // Use 12-hour format
        };
        const formattedDateTime = date.toLocaleDateString('en-US', options);
        const timeString = date.toLocaleTimeString('en-US', options);
      
        return `${formattedDateTime}`;
      }

    function MyUpdateModal(props) {
        return (
          <Modal
            {...props}
            
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add an Expense
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Select Expense Type</Form.Label>
                <Form.Select aria-label="Default select example" ref={expenseType} >
                <option value="General Expenses">General Expenses</option>
                <option value="Salary">Salary</option>
                <option value="Rent">Rent</option>
                <option value="Internet">Internet</option>
                <option value="Licenses">Licenses</option>
                <option value="Other">Other</option>
                <option value="Bookings Payout">Bookings Payout</option>
                </Form.Select>
            </Form.Group>
             
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="number" ref={expenseAmount} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Describe Expense</Form.Label>
                <Form.Control type="text" ref={expenseDescription} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Spent On</Form.Label>
                <Form.Control type="datetime-local" ref={expenseSpentOn} />
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button className='btn btn-dark' onClick={props.onHide}>Close</Button>


                <Button variant="primary"  onClick={addBeneficiaryCont} id='submitBtn'>
                Submit
                </Button>


                <Button variant="primary" disabled id='spinnerbtn' style={{ display : 'none' }}>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span> Updating...</span>
                </Button>
                
            </Modal.Footer>
          </Modal>
        );
    }

  const expenseType = useRef();
  const expenseAmount = useRef();
  const expenseDescription = useRef();
  const expenseSpentOn = useRef();


  function addBeneficiaryCont(){

    const addexpenseType = expenseType.current.value;
    const addexpenseAmount = expenseAmount.current.value;
    const addexpenseDescription = expenseDescription.current.value;
    const addexpenseSpentOn = expenseSpentOn.current.value;

    const timestamp = new Date();

    //temporarily use these but delete. Use state please
        document.getElementById("submitBtn").style.display = "none";
        document.getElementById("spinnerbtn").style.display = "block";

        onAuthStateChanged(auth, (user) => {
            if (user) {
            const uid = user.uid;
            // Add a new document with a generated id
            const newTenantRef = doc(collection(db, "expenses"));
            setDoc(newTenantRef, {

                theexpenseType: addexpenseType,
                theexpenseAmount: addexpenseAmount,
                theexpenseDescription: addexpenseDescription,
                theexpenseSpentOn:addexpenseSpentOn,

                docid:newTenantRef.id,
                myTimestamp: timestamp,
                addedBy:uid
            }).then(()=>{
               
                window.location.reload();
            })
    
            } else {
    
            }
        });

  }


  
    return (
      <div className="dataComp">
        <div className="dataCompTop">
          <h5>All Expenses</h5>
  
          <div className="searchContainer">
            <button onClick={() => setModalShowUp(true)}>Add an Expense</button>
          </div>
        </div>
  
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date </th>
              <th>Amount</th>
              <th>Expense Category</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {studentsList.map((mystudent) => (
              <tr key={mystudent.id}>
                 <td>{formatDateTime(mystudent.theexpenseSpentOn)}</td>
                <td>{mystudent.theexpenseAmount}</td>
                <td>{mystudent.theexpenseType}</td>
                <td>{mystudent.theexpenseDescription}</td>
                <td>
                  <button
                    className="btn-sm btn btn-danger"
                    onClick={() => deleteThisItem(mystudent.docid)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <MyUpdateModal show={modalShowUp} onHide={() => setModalShowUp(false)} />
      </div>
    );
  }

export default ExpensesComp