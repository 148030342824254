import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import ExpensesComp from './ExpensesComp'
import './Dash.css'

function Expenses() {
  return (
    <div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne'> 
        <div className='DashTop'>
          <h1>Expenses</h1>
          <NavBar/>
        </div>

        <div className='dataComp'>

            <ExpensesComp/>

        </div>
     </div>
    </div>
  )
}

export default Expenses