import React, { useRef, useState, useEffect } from 'react'
import './Navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt} from '@fortawesome/free-solid-svg-icons'
import { faBell} from '@fortawesome/free-solid-svg-icons'
import ProfImage from '../Images/defprofile.png'

import {useNavigate } from "react-router-dom";
import {app} from '../firebase.js'
import { getFirestore } from "@firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, setDoc,query, getDocs, deleteDoc, where} from "@firebase/firestore"; 
import swal from 'sweetalert';


function NavBar() {

    const db = getFirestore(app);
    const [loggedInUser, setloggedInUser] = useState("");
    let navigate = useNavigate();
  
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          // ...
  
          const j = query(
            collection(db, "admins"),
            where("userID", "==", uid)
          );
          getDocs(j).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let userName = doc.data().userName;
              setloggedInUser(userName)
            });
        
          });
  
  
        } else {
          // User is signed out
          navigate("/")  
        }
      });
  
  
  
  
    return (
      <div className='navbar'>
  
  
          <div className='notif'>
            <FontAwesomeIcon icon={faBell} className="dashNavIco"/>
          </div>
          
          <div className='prof'>
            <img src={ProfImage} alt="" />
          </div>
  
          <div>
           <p>{loggedInUser}</p> 
          </div>
  
  
  
  
      </div>
    )
}

export default NavBar