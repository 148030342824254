import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, doc, orderBy, setDoc,query, getDocs, deleteDoc, where, updateDoc} from "@firebase/firestore"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {useNavigate } from "react-router-dom";

function FlightsComp() {
  const [modalShow, setModalShow] = React.useState(false);
  const [studentsList, setStudentList] = useState([]);
  const db = getFirestore(app);
  const auth = getAuth();
  const storage = getStorage();
  let navigate = useNavigate();



    //fetch all menu items
    useEffect(() => {     
      const fetchData = async () => {  
        const q = query(collection(db, 'flights'));
      let studentsItem = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
          studentsItem.push({ id: doc.id, ...doc.data() });
          setStudentList([...studentsItem]);   
      });

  
    };
    fetchData();
    //end magic

    }, []);
    //end fetching menu items


    //delete buyer
    function deleteThisItem(docid){

      const q = query(collection(db, "flights"), where("docid", "==", docid));
      getDocs(q).then((QuerySnapshot)=>{
          QuerySnapshot.forEach((thdoc)=>{
              let itemname = thdoc.data().firstName;
              console.log(itemname)

              swal({
                title: `Delete an flights`,
                text: `Are you sure you want to delete this flights ?`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                  deleteDoc(doc(db, "flights", docid))
                  .then(()=>{
                    swal("Deleted", "" , "success");
                    swal(`flights has been deleted!`, {
                      icon: "success",
                    }).then(()=>{
                      window.location.reload();
                    })
                    
                  })

                } else {
                  swal("Cancelled");
                }
              });                    
          })
      })

  }


  function MyVerticallyCenteredModal(props) {
      return (
        <Modal
          {...props}
          
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add a Flight
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Select Cover Image</Form.Label>
              <Form.Control type="file" ref={fileInput}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Aircraft Name</Form.Label>
              <Form.Control type="text" ref={addAircraftName}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Aircraft Type</Form.Label>
                <Form.Select aria-label="Default select example" ref={addAircraftType} >
                <option value="helicopter">Helicopter</option>
                <option value="fixed wing">Fixed Wing</option>
                </Form.Select>
            </Form.Group> 

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Speed</Form.Label>
              <Form.Control type="text" ref={addSpeed} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Endurance</Form.Label>
              <Form.Control type="text" ref={addEndurance} />
            </Form.Group> 

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Capacity</Form.Label>
              <Form.Control type="text" ref={addCapacity}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Tekeoff Distance</Form.Label>
              <Form.Control type="text" ref={addTakeoffDistance}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" ref={addDescription}  as="textarea" style={{ height: '100px' }}/>
              </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Boarding Area</Form.Label>
              <Form.Control type="text" ref={addBoardingArea}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Operated By</Form.Label>
              <Form.Control type="text" ref={addOperatedBy}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Amount Per Hour</Form.Label>
              <Form.Control type="number" ref={addAmountPerHour} />
            </Form.Group>

    
          </Modal.Body>
          <Modal.Footer>
            <Button className='btn btn-dark' onClick={props.onHide}>Close</Button>
              <Button variant="primary"  onClick={addAnTenant} id='submitBtn'>
              Submit
              </Button>
              <Button variant="primary" disabled id='spinnerbtn' style={{ display : 'none' }}>
                  <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  />
                  <span> Submitting...</span>
              </Button>            
          </Modal.Footer>
        </Modal>
      );
  }

  //instantiate storage
  const fileInput = useRef();
  const addAircraftName = useRef();
  const addAircraftType = useRef();
  const addSpeed = useRef();
  const addEndurance = useRef();
  const addCapacity = useRef(); 
  const addTakeoffDistance = useRef(); 
  const addDescription = useRef();
  const addBoardingArea = useRef(); 
  const addOperatedBy = useRef(); 
  const addAmountPerHour = useRef();



  function addAnTenant(){

      const aircraftName = addAircraftName.current.value;
      const aircraftType = addAircraftType.current.value;
      const Speed = addSpeed.current.value;
      const Endurance = addEndurance.current.value;
      const Capacity = addCapacity.current.value;
      const Takeoffdistance = addTakeoffDistance.current.value;
      const flightDescription = addDescription.current.value;
      const price = addAmountPerHour.current.value;

      const theaddBoardingArea = addBoardingArea.current.value;
      const theaddOperatedBy = addOperatedBy.current.value;
      const theTimestamp = new Date();

      //temporarily use these but delete. Use state please
      document.getElementById("submitBtn").style.display = "none";
      document.getElementById("spinnerbtn").style.display = "block";

      //update profile picture 
      const profilePhoto = fileInput.current.files[0];   
      const storageRef = ref(storage, 'Flights/' + Math.random() + profilePhoto.name);
      const uploadTask = uploadBytesResumable(storageRef, profilePhoto);

          uploadTask.on('state_changed', 
              (snapshot) => {        
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  const roundedOffProgress = Math.round(progress);
                  console.log(roundedOffProgress);
                  
              }, 
          (error) => {
              // Handle unsuccessful uploads
          }, 
          () => {
              // Handle successful uploads on complete
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                onAuthStateChanged(auth, (user) => {
                  if (user) {
                  const uid = user.uid;
                  // Add a new document with a generated id
                  const newTenantRef = doc(collection(db, "flights"));
                  setDoc(newTenantRef, {


                      price:price,
                      flightDescription:flightDescription,
                      Takeoffdistance:Takeoffdistance,

                      Capacity:Capacity,
                      Endurance:Endurance,
                      Speed:Speed,
                      aircraftType:aircraftType,
                      aircraftName:aircraftName,
                      TakeOffArea:theaddBoardingArea,
                      operatedBy:theaddOperatedBy,

                      docID:newTenantRef.id,
                      timestamp: theTimestamp,
                      flightImage: downloadURL,
                      addedBy:uid,
                      Availability: "available",
                      minDurationInMinutes: "60",
                      flightType: "Tours"

                  }).then(()=>{
                      setModalShow(false)
                      //window.location.reload();
                      swal("Success", "Flight Added successfully!", "success").then((value) => {
                          window.location.reload(false);
                        });
                      
                  })
          
                  } else {
          
                  }
              });
  
              });
          }
          );
      
      //end profile picture

    }





  return (
    <div>
        <div className="dataCompTop">
          <h5>All Flights</h5>
  
          <div className="searchContainer">
          <button onClick={() => setModalShow(true)}>Add a Flight</button>
          </div>
        </div>


        {studentsList.map((mystudent) => (
        <div className='experienceDiv' key={Math.random()}>
          <div className='experienceDivImageCont'>
            <img src={mystudent.flightImage} alt="" />
          </div>
          <h5>{mystudent.aircraftName}</h5>
          <p>{mystudent.Capacity}</p>
          <p>{mystudent.aircraftType}</p>
          <p>KES. {mystudent.price} per hour</p>
        </div>
        ))}

       



        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  )
}

export default FlightsComp