import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import DiscoveryComp from './DiscoveryComp'

function Discovery() {
  return (
    <div className='contentMain'> 
    <LeftBar/>
    <div className='theRightOne'> 
      <div className='DashTop'>
        <h1>Discovery</h1>
        <NavBar/>
      </div>

      <div className='dataComp'>

          <DiscoveryComp/>

      </div>
   </div>
  </div>
  )
}

export default Discovery