import React from 'react'
import dashImage from '../Images/dashone.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faChartSimple} from '@fortawesome/free-solid-svg-icons'
import { faChartLine} from '@fortawesome/free-solid-svg-icons'
import { faHelicopter} from '@fortawesome/free-solid-svg-icons'


function DashComp() {
  return (
    <div className='dashStuff'>

        <div className='cardOne'>
          <div>
            <p>Total Users</p>
            <h3>45</h3>
          </div>
          <FontAwesomeIcon icon={faUsers} className='dashIcon'/>
        </div>

        <div className='cardTwo'>
        <div>
            <p>Total Bookings</p>
            <h3>45</h3>
          </div>
          <FontAwesomeIcon icon={faHelicopter} className='dashIcon'/>

        </div>


        <div className='cardThree'>
        <div>
            <p>Income</p>
            <h3>45</h3>
          </div>
          <FontAwesomeIcon icon={faChartSimple} className='dashIcon'/>

        </div>

        <div className='cardFour'>
        <div>
            <p>Expense</p>
            <h3>45</h3>
          </div>
          <FontAwesomeIcon icon={faChartLine} className='dashIcon'/>

        </div>

      


    </div>
  )
}

export default DashComp