import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, orderBy, query, getDocs } from '@firebase/firestore';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function IncomeComp() {
    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app);
  
  
    //fetch all menu items
    useEffect(() => {     
              const fetchData = async () => {  
                const q = query(collection(db, 'Bookings'), orderBy('timestamp', 'desc'));
              let studentsItem = [];
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                  studentsItem.push({ id: doc.id, ...doc.data() });
                  setStudentList([...studentsItem]);   
              });
      
          
          };
      fetchData();
      //end magic
  
      }, []);
      //end fetching menu items
  
  
    function viewCustomer() {
      // Implement your viewCustomer function here
    }
  
    return (
      <div className="dataComp">
        <div className="dataCompTop">
          <h5>All Income</h5>
  
          <div className="searchContainer">
            <FontAwesomeIcon icon={faSearch} className="searchIcon" />
            <input type="text" placeholder="Type to search" />
            <button>Go</button>
          </div>
        </div>
  
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Income Date and Time</th>
              <th>Amount</th>
              <th>Income From</th>
              <th>View Booking</th>
            </tr>
          </thead>
          <tbody>
            {studentsList.map((mystudent) => (
              <tr key={mystudent.id}>
                <td>{mystudent.timestamp.toDate().toLocaleString()}</td>
                <td>{mystudent.amountPaid}</td>
                <td>{mystudent.bookingType}</td>    

                <td>
                  <button
                    className="btn-sm btn btn-primary"
                    onClick={() => viewCustomer(mystudent.userId)}
                  >
                    View Booking
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
export default IncomeComp