import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import UsersComp from './UsersComp'
import './Dash.css'

function Users() {
  return (
<div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne'> 
        <div className='DashTop'>
          <h1>Clients</h1>
          <NavBar/>
        </div>

        <div className='dataComp'>

            <UsersComp/>

        </div>
     </div>
    </div>
  )
}

export default Users