import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, query, where, orderBy, getDocs } from '@firebase/firestore';


import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function FlightBookings() {
  const [studentsList, setStudentList] = useState([]);
  const db = getFirestore(app);


    useEffect(() => {
      const fetchData = async () => {
        const q = query(
          collection(db, 'Bookings'),
          where('bookingType', '==', 'Helicopter Tours' || 'bookingType', '==', 'Helicopter Charters' || 'bookingType', '==', 'Small Aircraft Tours' || 'bookingType', '==', 'Small Aircraft Charters'), 
          orderBy('timestamp', 'desc')
        );
    
        try {
          const querySnapshot = await getDocs(q);
          let studentsItem = [];
          
          querySnapshot.forEach((doc) => {
            studentsItem.push({ id: doc.id, ...doc.data() });
          });
    
          // Set the state outside the loop to update once with all data
          setStudentList([...studentsItem]);
        } catch (error) {
          // Handle any errors here
          console.error('Error fetching data: ', error);
        }
      };
    
      fetchData();
    }, [db, setStudentList]); 
    




  function viewCustomer() {
    // Implement your viewCustomer function here
  }

  return (
    <div className="dataComp">

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Aircraft Nameg</th>
            <th>Flight Type</th>
            <th>Flight Date</th>
            <th>Passengers</th>
            <th>Amount Paid</th>
            <th>Status</th>
            <th>View Booking</th>
          </tr>
        </thead>
        <tbody>
          {studentsList.map((mystudent) => (
            <tr key={mystudent.id}>

              <td>{mystudent.sendDesc}</td>
              <td>{mystudent.bookingType}</td>
              <td>{mystudent.selectedDay} at {mystudent.selectedTime}</td>
              <td>{mystudent.noOfPassangers}</td>
              <td>{mystudent.amountPaid}</td>
              <td>{mystudent.status}</td>

              <td><button
                  className="btn-sm btn btn-primary"
                  onClick={() => viewCustomer(mystudent.documentId)}>
                  View Booking
                  </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
export default FlightBookings