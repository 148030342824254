import React, { useRef, useState } from 'react'
import './Auth.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

//routers
import {useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";

import swal from 'sweetalert';

import {app} from '../firebase.js'
import { getFirestore } from "@firebase/firestore";

import logo from '../Images/logoblack.svg'

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, getDocs, where } from "@firebase/firestore";



function Login() {
    let navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
  
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);
  
     function loginUser(){
          const email = emailRef.current.value;
          const password = passwordRef.current.value;
  
  
          setShowProgress(true);
          setShowSignInbtn(false)
  
          const auth = getAuth();
          const db = getFirestore(app);
            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                let userID = userCredential.user.uid
                // ...
                navigate("/dashboard")
  
                const q = query(collection(db, "admins"), where("userId", "==", userID));
                  getDocs(q).then((QuerySnapshot)=>{
                      QuerySnapshot.forEach((doc)=>{
  
                          let userType = doc.data().userType;
  
                          if (userType === "superAdmin" ){
                              navigate("/dashboard")
                          }else{
                              navigate("/admin/dashboard")
                          }             
                          
                      })
                  })
              })
              .catch((error) => {
        
                setShowProgress(false)
                setShowSignInbtn(true)
                const errorCode = error.code;
                const errorMessage = error.message;
                swal("Login Error!", +"" +errorMessage + "",  "error");
  
                // ..
      });
  
  
  
      
    }

  
    return (
      <div className='mainAuth'>
  
          <div className='AuthLeft'>
  
            <div className='authleftCont'>
              <video autoPlay muted loop src="https://res.cloudinary.com/ubunifu/video/upload/v1704724179/pexels-ana-benet-8242842_1080p_mlammz.mp4"></video>
              <div className='authLeftOpacity'></div>
            </div>
            
  
          </div>
  
          <div className='AuthRight'>
  
                <img src={logo} alt="" className='logoAuth' />
                {/* <h3>Fly Pepea</h3> */}
                <br />
   
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="enter email" ref={emailRef}/>
  
                </Form.Group>
  
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="password" ref={passwordRef} />
                </Form.Group>
  
                {showSignInbtn && 
                <Button variant="primary" type="submit" className='btnLogin' onClick={loginUser}>
                Sign in
                </Button>
                }
  
                {showProgress && 
                    <Button variant="dark" disabled  className='btnLogin'>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                        <span> Signing in...</span>
                    </Button>
                }
{/*   
                <Link to="/forgot-password" className='signuptext '> Forgot Password?</Link> */}
       
  
          </div>
      </div>
    )
}

export default Login