import React from 'react'
import './Leftbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faChartPie} from '@fortawesome/free-solid-svg-icons'
import { faLineChart} from '@fortawesome/free-solid-svg-icons'
import { faPlane} from '@fortawesome/free-solid-svg-icons'
import { faThLarge} from '@fortawesome/free-solid-svg-icons'
import { faHelicopter} from '@fortawesome/free-solid-svg-icons'
import { faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons'
import { faFaceSmile} from '@fortawesome/free-solid-svg-icons'
import { faTag} from '@fortawesome/free-solid-svg-icons'
import { faMoneyCheck} from '@fortawesome/free-solid-svg-icons'
import { faFaceAngry} from '@fortawesome/free-solid-svg-icons'
import { faBagShopping} from '@fortawesome/free-solid-svg-icons'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faVideo} from '@fortawesome/free-solid-svg-icons'
import { faMugHot} from '@fortawesome/free-solid-svg-icons'
import { faChartSimple} from '@fortawesome/free-solid-svg-icons'
import { faClipboard} from '@fortawesome/free-solid-svg-icons'
import { faBriefcase} from '@fortawesome/free-solid-svg-icons'
import { faAddressCard} from '@fortawesome/free-solid-svg-icons'
//routers
import {useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";
import {NavLink } from "react-router-dom";

import logo from '../Images/logowhite.svg'

import {app} from '../firebase.js'
import { getAuth, signOut } from "firebase/auth";


function LeftBar() {

    let navigate = useNavigate();


    function logoutUser(){
        const auth = getAuth();
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/")  
        }).catch((error) => {

        });
    }


  return (
    <div className='leftbar'>

    <div className='menuContainer'>

        <div className='logocontainer'>
            <img src={logo} alt="" className='logoDash' />
        </div>

        
        <div className='leftLinkCont d-flex align-items-center' >
            <NavLink to="/dashboard" className='leftNavLinks ' >
                <FontAwesomeIcon icon={faThLarge} className="laftNavIcon"/>
                Dashboard
            </NavLink>
        </div>

        <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/flights" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faPlane} className="laftNavIcon"/>
                    Flights
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/experiences" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faHelicopter} className="laftNavIcon"/>
                    Experiences
                </NavLink>
            </div>


            <hr />

        <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/users" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faAddressCard} className="laftNavIcon"/>
                    Users
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/bookings" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faFaceSmile} className="laftNavIcon"/>
                    Bookings
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/discovery" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faVideo} className="laftNavIcon"/>
                    Discover Experiences
                </NavLink>
            </div>

            <hr />

            {/* <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/reports" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faChartPie} className="laftNavIcon"/>
                   Reports
                </NavLink>
            </div> */}

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/income" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faMoneyCheck} className="laftNavIcon"/>
                   Income
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
            <NavLink to="/expenses" className='leftNavLinks '>
                <FontAwesomeIcon icon={faLineChart} className="laftNavIcon"/>
                Expenses
            </NavLink>
            </div>

    </div>

    <div className='footerMenu'>
        <div className='logoutMenu' onClick={logoutUser}>
            <div className='leftLinkCont d-flex align-items-center'>
                <div  className='leftNavLinks logoutbtn'>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} className="laftNavIcon"/>
                    Logout
                </div>
            </div>
        </div>

    </div>





</div>
  )
}

export default LeftBar