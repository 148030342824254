import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.css';

///
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Bookings from './Pages/Bookings';

import Discovery from './Pages/Discovery';
import Expenses from './Pages/Expenses';
import Experiences from './Pages/Experiences';
import Flights from './Pages/Flights';
import Income from './Pages/Income';
import Reports from './Pages/Reports';
import Users from './Pages/Users';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/bookings" element={<Bookings />} />

              <Route path="/discovery" element={<Discovery />} />
              <Route path="/expenses" element={<Expenses />} />
              <Route path="/experiences" element={<Experiences />} />

              <Route path="/income" element={<Income />} />
              <Route path="/flights" element={<Flights />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/users" element={<Users />} />
              
          </Routes>
      </BrowserRouter> 
    </div>
  );
}

export default App;
