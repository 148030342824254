import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import ExperiencesComp from './ExperiencesComp'


function Experiences() {
  return (
<div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne'> 
        <div className='DashTop'>
          <h1>Experiences</h1>
          <NavBar/>
        </div>

        <div className='dataComp'>

            <ExperiencesComp/>

        </div>
     </div>
    </div>
  )
}

export default Experiences