import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, doc, orderBy, setDoc,query, getDocs, deleteDoc, where, updateDoc} from "@firebase/firestore"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {useNavigate } from "react-router-dom";

function DiscoveryComp() {


    const [modalShow, setModalShow] = React.useState(false);
    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app);
    const auth = getAuth();
    const storage = getStorage();
    let navigate = useNavigate();
  
  
    //fetch all menu items
    useEffect(() => {     
              const fetchData = async () => {  
                const q = query(collection(db, 'Videos'), orderBy('myTimestamp', 'desc'));
              let studentsItem = [];
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                  studentsItem.push({ id: doc.id, ...doc.data() });
                  setStudentList([...studentsItem]);   
              });
      
          
          };
      fetchData();
      //end magic
  
      }, []);
      //end fetching menu items
  
  
    //delete buyer
    function deleteThisItem(docid){

        const q = query(collection(db, "expenses"), where("docid", "==", docid));
        getDocs(q).then((QuerySnapshot)=>{
            QuerySnapshot.forEach((thdoc)=>{
                let itemname = thdoc.data().firstName;
                console.log(itemname)

                swal({
                  title: `Delete an Expense`,
                  text: `Are you sure you want to delete this expense ?`,
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    deleteDoc(doc(db, "expenses", docid))
                    .then(()=>{
                      swal("Deleted", "" , "success");
                      swal(`Expense has been deleted!`, {
                        icon: "success",
                      }).then(()=>{
                        window.location.reload();
                      })
                      
                    })

                  } else {
                    swal("Cancelled");
                  }
                });                    
            })
        })

    }


    function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Upload a video
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Select Video</Form.Label>
                <Form.Control type="file" ref={fileInput}/>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" ref={videoTitle}/>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Tags</Form.Label>
                <Form.Control type="text" ref={videoTags} />
              </Form.Group>


            </Modal.Body>
            <Modal.Footer>
              <Button className='btn btn-dark' onClick={props.onHide}>Close</Button>
                <Button variant="primary"  onClick={addAnTenant} id='submitBtn'>
                Upload
                </Button>
                <Button variant="primary" disabled id='spinnerbtn' style={{ display : 'none' }}>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span>Uploading...</span>
                </Button>            
            </Modal.Footer>
          </Modal>
        );
    }

    //instantiate storage

    
    const fileInput = useRef();
    const videoTitle = useRef();
    const videoTags = useRef();



    function addAnTenant(){

        const addvideoTitle = videoTitle.current.value;
        const addvideoTags = videoTags.current.value;
        const timestamp = new Date();
  
        //temporarily use these but delete. Use state please
        document.getElementById("submitBtn").style.display = "none";
        document.getElementById("spinnerbtn").style.display = "block";
  
        //update profile picture 
        const profilePhoto = fileInput.current.files[0];   
        const storageRef = ref(storage, 'videos/' + Math.random() + profilePhoto.name);
        const uploadTask = uploadBytesResumable(storageRef, profilePhoto);
  
            uploadTask.on('state_changed', 
                (snapshot) => {        
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const roundedOffProgress = Math.round(progress);
                    console.log(roundedOffProgress);
                    
                }, 
            (error) => {
                // Handle unsuccessful uploads
            }, 
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  
                  onAuthStateChanged(auth, (user) => {
                    if (user) {
                    const uid = user.uid;
                    // Add a new document with a generated id
                    const newTenantRef = doc(collection(db, "Videos"));
                    setDoc(newTenantRef, {
  
                        thevideoTitle:addvideoTitle,
                        thevideoTags:addvideoTags,
                        docid:newTenantRef.id,
                        myTimestamp: timestamp,
                        videoUrl: downloadURL,
                        addedBy:uid 
                    }).then(()=>{
                        setModalShow(false)
                        //window.location.reload();
                        swal("Success", "Video uploaded successfully!", "success").then((value) => {
                            window.location.reload(false);
                          });
                        
                    })
            
                    } else {
            
                    }
                });
    
                });
            }
            );
        
        //end profile picture
  
      }
  


  return (
    <div>

<div className="dataCompTop">
          <h5>Experience Videos</h5>
  
          <div className="searchContainer">
            <button onClick={() => setModalShow(true)}>Upload a Video</button>
          </div>
        </div>

        

        {studentsList.map((mystudent) => (
  <div className='videoItem' key={Math.random()}>
    <video
      src={mystudent.videoUrl}
      controls={false} // Initially hide controls
      onMouseEnter={(e) => e.target.controls = true} // Show controls on hover
      onMouseLeave={(e) => e.target.controls = false} // Hide controls on mouse leave
    ></video>
  </div>
))}


        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  )
}

export default DiscoveryComp