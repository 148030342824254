import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import IncomeComp from './IncomeComp'
import './Dash.css'


function Income() {
  return (
    <div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne'> 
        <div className='DashTop'>
          <h1>Income</h1>
          <NavBar/>
        </div>

        <div className='dataComp'>

            <IncomeComp/>

        </div>
     </div>
    </div>
  )
}

export default Income