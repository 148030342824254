// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional



const firebaseConfig = {
  apiKey: "AIzaSyDCwV8R8T3dGElQUR60Vt7Fu4NtJegnk_M",
  authDomain: "pepea-a9eaa.firebaseapp.com",
  projectId: "pepea-a9eaa",
  storageBucket: "pepea-a9eaa.appspot.com",
  messagingSenderId: "98164929390",
  appId: "1:98164929390:web:008e0e47ba47191048c77f",
  measurementId: "G-PQ3PDXVZ0Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export {app};
export {analytics}


